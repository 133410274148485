/**
 * Created by MBX on 7/12/2017.
 */

import { ColumnDataType } from '../../model';

export const EXTERNAL_APPOINTMENTS_TABLE_CONF = [
	{
		label: 'id',
		value: 'id',
		hidden: true,
		header: 'ID',
		sortField: 'id',
		sortable: true,
		searchable: true,
		required: false,
		order: 1,
		id: 0,
		type: ColumnDataType.NUMERIC,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'startDate',
		value: 'startDate',
		hidden: false,
		header: 'DATE',
		sortField: 'startDate',
		sortable: true,
		searchable: true,
		required: true,
		order: 2,
		id: 0,
		type: ColumnDataType.DATE,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'startTime',
		value: 'startTime',
		hidden: false,
		header: 'TIME',
		sortField: 'startTime',
		sortable: true,
		searchable: true,
		required: true,
		order: 3,
		id: 0,
		type: ColumnDataType.DATE,
		defaultValue: '',
		selected: true,
		unit: 'TIME',
	},
	{
		label: 'patientName',
		value: 'patientName',
		hidden: false,
		header: 'PATIENT_NAME',
		sortField: 'patientName',
		sortable: true,
		searchable: true,
		required: true,
		order: 4,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'referringPhysicianFullName',
		value: 'referringPhysicianFullName',
		hidden: false,
		header: 'REFERRING_PHYSICIAN',
		sortField: 'referringPhysicianFullName',
		sortable: true,
		searchable: true,
		required: true,
		order: 5,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'appointmentReason',
		value: 'appointmentReason',
		hidden: false,
		header: 'APT_REASON',
		sortField: 'appointmentReason',
		sortable: true,
		searchable: true,
		required: true,
		order: 6,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'appointmentStatus',
		value: 'appointmentStatus',
		hidden: false,
		header: 'APPOINTMENT_STATUS',
		sortField: 'appointmentStatus',
		sortable: true,
		searchable: true,
		required: true,
		order: 7,
		id: 0,
		type: ColumnDataType.STATUS,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'appointmentSource',
		value: 'appointmentSource',
		hidden: false,
		header: 'Source',
		sortField: 'appointmentSource',
		sortable: true,
		searchable: true,
		required: true,
		order: 8,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'priorityId',
		value: 'priorityId',
		hidden: false,
		header: 'PRIORITY',
		sortField: 'priorityId',
		sortable: true,
		searchable: true,
		required: true,
		order: 9,
		id: 0,
		type: 'ID',
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'examType',
		value: 'examType',
		hidden: false,
		header: 'EXAMTYPE',
		sortField: 'examType',
		sortable: true,
		searchable: true,
		required: true,
		order: 10,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'modality',
		value: 'modality',
		hidden: false,
		header: 'MODALITY',
		sortField: 'modality',
		sortable: true,
		searchable: true,
		required: true,
		order: 11,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'cancellationReason',
		value: 'cancellationReason',
		hidden: false,
		header: 'CANCELLATIONREASON',
		sortField: 'cancellationReason',
		sortable: true,
		searchable: true,
		required: true,
		order: 12,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'comments',
		value: 'comments',
		hidden: false,
		header: 'COMMENTS',
		sortField: 'comments',
		sortable: true,
		searchable: true,
		required: true,
		order: 13,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
];
