import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	APT_FOR_RESULT_STATUSES,
	AptStatus,
	getRdvStatusIcon,
	OrderFilter,
} from '../shared/util';
import { MatTableDataSource } from '@angular/material/table';
import { AppointmentDTO } from '../../model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, merge, of as observableOf, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SchedulerService } from '../scheduler.service';
import { DeleteConfirmComponent, LocalStorageService } from '../../shared';
import { WsService } from '../../ws.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { get, sortBy, union } from 'lodash';
import {
	catchError,
	debounceTime,
	filter,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import moment from 'moment';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateUtils } from '../../utils';
import { APPT_FOR_RESULT_TABLE_CONF } from './table-conf';
import { getOrderStatusColor } from '../shared/util';
import { rowsAnimation } from '../../animations';

@Component({
	selector: 'ft-appointments-for-result',
	templateUrl: './appointments-for-result.component.html',
	styleUrls: ['./appointments-for-result.component.scss'],
	animations: [rowsAnimation],
})
export class AppointmentsForResultComponent
	implements AfterViewInit, OnInit, OnDestroy
{
	cols = [];

	displayedColumns = APPT_FOR_RESULT_TABLE_CONF;
	columnsToDisplay: string[] = [];

	appointmentStatuses = APT_FOR_RESULT_STATUSES;
	profile: any;

	canViewConfData: boolean;

	resultsLength = 0;
	isLoadingResults = true;

	isResultsLoaded = false;

	dataSource = new MatTableDataSource<AppointmentDTO>();

	filterForm: FormGroup;

	filterChange = new BehaviorSubject('');
	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	private orderFilter = new OrderFilter();
	private orderFilterSubject = new BehaviorSubject<OrderFilter>(
		new OrderFilter()
	);
	todayFormat = 'HH:mm';
	private query = new BehaviorSubject<string>(null);
	defaultPageSize = 50;
	private subs: Subscription[] = [];

	constructor(
		private service: SchedulerService,
		private localStorage: LocalStorageService,
		private wsService: WsService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.displayedColumns = APPT_FOR_RESULT_TABLE_CONF;

		this.columnsToDisplay = sortBy(
			APPT_FOR_RESULT_TABLE_CONF.filter(item => !item.hidden),
			'order'
		).map(it => it.label);
		this.columnsToDisplay = union(this.columnsToDisplay, ['action']);

		this.createFilterForm();

		this.defaultPageSize =
			this.localStorage.getItem('order_page_size') || 50;

		const user = get(this.route.snapshot.data, 'user');
		this.profile = get(user, 'profile');
		this.canViewConfData = user.canViewConfidentialData;

		this.wsService.observeTopic('scheduler').subscribe(res => {
			if (res.topic === 'scheduler')
				this.orderFilterSubject.next(this.orderFilter);
		});
	}

	ngAfterViewInit() {
		this.buildQuery();
		this.resetPaginator();

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.query.pipe(debounceTime(250)),
		];

		merge(...observedFilters)
			.pipe(
				startWith(''),
				filter(it => it !== ''),
				switchMap(query => {
					this.isLoadingResults = true;
					this.localStorage.setItem(
						'order_page_size',
						this.paginator.pageSize
					);
					return this.service.getAppointmentsForResult(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						query
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isResultsLoaded = false;
					this.resultsLength = data.totalElements;
					return data.content;
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isResultsLoaded = true;
					return observableOf([]);
				})
			)
			.subscribe((data: AppointmentDTO[]) => {
				this.dataSource.data = data;
			});
	}

	trackById(index: number, item: any): string {
		return item.id;
	}

	private resetPaginator = () =>
		this.query.subscribe(() => (this.paginator.pageIndex = 0));

	openPatientFolder(row) {
		this.router.navigate(['/patients/folder', row.patientId]);
	}

	markAppointmentAsMissed(row: AppointmentDTO) {
		this.subs.push(
			this.service
				.updateAppointmentDTOStatus(row.id, AptStatus.missed)
				.subscribe(value => {
					if (value) this.query.next(this.query.getValue());
				})
		);
	}

	markAppointmentAsCompleted(row: AppointmentDTO) {
		this.subs.push(
			this.service
				.updateAppointmentDTOStatus(row.id, AptStatus.completed)
				.subscribe(value => {
					if (value) this.query.next(this.query.getValue());
				})
		);
	}

	onCancelAppointment(apt: AppointmentDTO) {
		this.subs.push(
			this.dialog
				.open(DeleteConfirmComponent)
				.afterClosed()
				.subscribe(ok => {
					if (ok) {
						this.service
							.updateAppointmentDTOStatus(
								apt.id,
								AptStatus.canceled
							)
							.subscribe(() =>
								this.orderFilterSubject.next(this.orderFilter)
							);
					}
				})
		);
	}

	deleteApt(apt) {
		this.subs.push(
			this.dialog
				.open(DeleteConfirmComponent)
				.afterClosed()
				.subscribe(ok => {
					if (ok) {
						this.service
							.deleteAppointment(apt.id)
							.subscribe(() =>
								this.orderFilterSubject.next(this.orderFilter)
							);
					}
				})
		);
	}

	ngOnDestroy() {
		this.subs.forEach(it => it.unsubscribe());
	}

	ngOnInit() {
		this.filter.nativeElement.focus();
	}

	getRdvStatusIcon = (status: string): string => getRdvStatusIcon(status);
	getOrderStatusColor = (status: string): string =>
		getOrderStatusColor(status);

	changePeriod() {
		this.filterForm.get('period').patchValue('OT');
	}

	changeRange(e: MatButtonToggleChange) {
		const dateRange = DateUtils.PeriodDateRange(e.value);
		this.filterForm.patchValue(dateRange);
		this.orderFilter.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
		this.orderFilterSubject.next(this.orderFilter);
	}

	private createFilterForm() {
		this.filterForm = this.fb.group({
			key: '',
			startDate: new Date(),
			endDate: new Date(),
			period: 'TODAY',
			physicianId: null,
			appointmentStatus: null,
		});

		this.filterForm.valueChanges.subscribe(value =>
			this.buildOrderFilter(value)
		);
	}

	private buildOrderFilter(value: any) {
		this.todayFormat =
			value.period === 'TODAY' ? 'HH:mm' : 'dd/MM/yyyy HH:mm';

		const startDate = moment(value.startDate).isValid()
			? moment(value.startDate)
			: moment().subtract(10, 'year');
		const endDate = moment(value.endDate).isValid()
			? moment(value.endDate)
			: moment().add(10, 'd');

		const start = startDate.format('YYYYMMDD');
		const end = endDate.format('YYYYMMDD');

		this.orderFilter.key = value.key;
		this.orderFilter.dateRange = `${start}-${end}`;
		const oStatus = value.appointmentStatus;
		this.orderFilter.appointmentStatus =
			oStatus && oStatus.length !== 0 ? oStatus.join('-') : 'ALL';
		this.orderFilter.physicianId = value.physicianId;

		this.orderFilterSubject.next(this.orderFilter);
	}

	private buildQuery() {
		this.orderFilterSubject
			.asObservable()
			.subscribe((orderFilter: OrderFilter) => {
				this.query.next(
					[
						orderFilter.key.replace('@', ''),
						orderFilter.dateRange,
						orderFilter.physicianId,
						orderFilter.appointmentStatus,
					].join('@')
				);
			});
	}
}
