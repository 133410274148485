<div class="card-view fx-layout-column relative">
	<!--search area-->
	<div
		[formGroup]="filterForm"
		class="fx-search-area fx-layout-row fx-content-end fx-items-end fx-gap-4 fx-padding-8">
		<mat-form-field subscriptSizing="dynamic">
			<input
				#filter
				[placeholder]="'SEARCH' | translate"
				formControlName="key"
				matInput />
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
		</mat-form-field>

		<span class="fx-grow-1"></span>

		<div class="fx-layout-column-nowrap">
			<h5 style="margin: 6px 0">
				{{ 'APPOINTMENT_STATUS' | translate }}
			</h5>
			<mat-button-toggle-group
				aria-label="appointment status"
				formControlName="appointmentStatus"
				multiple
				name="appointmentStatus">
				@for (status of appointmentStatuses; track status) {
					<mat-button-toggle
						[matTooltip]="status | translate"
						[value]="status">
						<mat-icon
							[fontIcon]="getRdvStatusIcon(status)"
							[ngStyle]="{
								background:
									'linear-gradient(45deg, black, ' +
									getOrderStatusColor(status) +
									', white)',
								'-webkit-background-clip': 'text',
								'-webkit-text-fill-color': 'transparent'
							}"
							fontSet="mdi"></mat-icon>
					</mat-button-toggle>
				}
			</mat-button-toggle-group>
		</div>

		@if (group.value === 'OT') {
			<div class="fx-layout-row-nowrap fx-content-start fx-items-end">
				<mat-form-field subscriptSizing="dynamic" style="width: 208px">
					<mat-date-range-input [rangePicker]="picker">
						<input
							[placeholder]="'FROM' | translate"
							formControlName="startDate"
							matStartDate
							(dateInput)="changePeriod()" />
						<input
							[placeholder]="'TO' | translate"
							formControlName="endDate"
							matEndDate
							(dateInput)="changePeriod()" />
					</mat-date-range-input>
					<mat-datepicker-toggle
						[for]="picker"
						matSuffix></mat-datepicker-toggle>
					<mat-date-range-picker
						#picker
						(closed)="changePeriod()"></mat-date-range-picker>
				</mat-form-field>
			</div>
		}

		<mat-button-toggle-group
			#group="matButtonToggleGroup"
			(change)="changeRange($event)"
			aria-label="Period"
			formControlName="period"
			name="period">
			<mat-button-toggle value="1M">
				-{{ 'ONE_MONTH' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="1W">
				-{{ 'ONE_WEEK' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="TODAY">
				{{ 'TODAY' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="1W+">
				+{{ 'ONE_WEEK' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="1M+">
				+{{ 'ONE_MONTH' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="3M+">
				+{{ 'THREE_MONTHS' | translate }}
			</mat-button-toggle>
			<mat-button-toggle value="OT">
				{{ 'ALL' | translate }}
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>

	<!--table-->
	@if (isLoadingResults) {
		<mat-progress-bar
			color="accent"
			mode="indeterminate"></mat-progress-bar>
	}

	<div class="fx-fill-height fx-overflow-auto">
		<mat-table
			[dataSource]="dataSource"
			[trackBy]="trackById"
			matSort
			matSortActive="id"
			matSortDirection="desc"
			matSortDisableClear>
			<!--Table-->
			@for (col of displayedColumns; track col) {
				<ng-container
					[class.hidden]="col.hidden"
					matColumnDef="{{ col.label }}">
					<mat-header-cell
						*matHeaderCellDef
						mat-sort-header="{{ col.sortField }}">
						{{ col.header | translate }}
					</mat-header-cell>

					@if (col.header === 'DATE') {
						<mat-cell *matCellDef="let row">
							{{ row[col.label] | date: 'dd/MM/yyyy' }}</mat-cell
						>
					}

					@if (col.header === 'TIME') {
						<mat-cell *matCellDef="let row">
							{{ row[col.label] | date: 'HH:mm' }}</mat-cell
						>
					}

					@if (col.header === 'APPOINTMENT_STATUS') {
						<mat-cell *matCellDef="let row">
							<span
								class="ft-badge"
								[style.background-color]="
									getOrderStatusColor(row[col.label]) + '60'
								"
								[style.font-weight]="500"
								>{{ row[col.label] | translate }}</span
							>
						</mat-cell>
					}

					@if (
						!['DATE', 'TIME', 'APPOINTMENT_STATUS'].includes(
							col.header
						)
					) {
						<mat-cell *matCellDef="let row">
							{{ row[col.value] }}</mat-cell
						>
					}
				</ng-container>
			}

			<!--actions-->
			<ng-container matColumnDef="action" stickyEnd>
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell
					*matCellDef="let row"
					class="fx-layout-row-nowrap fx-content-end fx-items-center">
					@if (row['appointmentStatus'] === 'planned') {
						<button
							(click)="markAppointmentAsCompleted(row)"
							[matTooltip]="'MARK_COMPLETED' | translate"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-calendar-check"
								fontSet="mdi"></mat-icon>
						</button>
					}
					@if (row['appointmentStatus'] === 'planned') {
						<button
							(click)="markAppointmentAsMissed(row)"
							[matTooltip]="'MARK_MISSED' | translate"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-calendar-export"
								fontSet="mdi"></mat-icon>
						</button>
					}
					@if (row['appointmentStatus'] === 'planned') {
						<button
							(click)="onCancelAppointment(row)"
							[matTooltip]="'CANCEL' | translate"
							mat-icon-button>
							<mat-icon
								fontIcon="mdi-close-circle"
								fontSet="mdi"></mat-icon>
						</button>
					}
					<button
						(click)="deleteApt(row)"
						[matTooltip]="'DELETE' | translate"
						mat-icon-button>
						<mat-icon
							fontIcon="mdi-delete"
							fontSet="mdi"></mat-icon>
					</button>
				</mat-cell>
			</ng-container>

			<!--header-->
			<mat-header-row
				*matHeaderRowDef="
					columnsToDisplay;
					sticky: true
				"></mat-header-row>
			<mat-row
				[@rowsAnimation]=""
				*matRowDef="let row; columns: columnsToDisplay"></mat-row>
		</mat-table>

		<div
			[style.display]="resultsLength === 0 ? '' : 'none'"
			class="fx-table-empty">
			{{ 'NOFOUND' | translate }}
		</div>
	</div>

	<mat-paginator
		[length]="dataSource.data.length"
		[pageIndex]="0"
		[pageSizeOptions]="[10, 25, 50, 100]"
		[pageSize]="defaultPageSize"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
