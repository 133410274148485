/**
 * Created by MBX on 7/12/2017.
 */

import { ColumnDataType, TableColumn } from '../../model';

export const APPT_FOR_RESULT_TABLE_CONF: TableColumn[] = [
	{
		label: 'id',
		value: 'id',
		hidden: true,
		header: 'ID',
		sortField: 'id',
		sortable: true,
		searchable: true,
		required: false,
		order: 1,
		id: 0,
		type: ColumnDataType.NUMERIC,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'startDate',
		value: 'startDate',
		hidden: false,
		header: 'DATE',
		sortField: 'startDate',
		sortable: true,
		searchable: true,
		required: true,
		order: 2,
		id: 0,
		type: ColumnDataType.DATE,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'startTime',
		value: 'startTime',
		hidden: false,
		header: 'TIME',
		sortField: 'startTime',
		sortable: true,
		searchable: true,
		required: true,
		order: 3,
		id: 0,
		type: ColumnDataType.DATE,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'patientName',
		value: 'patientName',
		hidden: false,
		header: 'PATIENT_NAME',
		sortField: 'patient_name',
		sortable: true,
		searchable: true,
		required: true,
		order: 4,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'appointmentReason',
		value: 'appointmentReason',
		hidden: false,
		header: 'PROCEDURE_CODE',
		sortField: 'appointmentReason',
		sortable: true,
		searchable: true,
		required: true,
		order: 6,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'appointmentStatus',
		value: 'appointmentStatus',
		hidden: false,
		header: 'APPOINTMENT_STATUS',
		sortField: 'appointmentStatus',
		sortable: true,
		searchable: true,
		required: true,
		order: 7,
		id: 0,
		type: ColumnDataType.STATUS,
		defaultValue: '',
		selected: true,
		unit: '',
	},
	{
		label: 'comments',
		value: 'comments',
		hidden: false,
		header: 'COMMENTS',
		sortField: 'comments',
		sortable: true,
		searchable: true,
		required: true,
		order: 9,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: '',
	},
];
