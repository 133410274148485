import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject, merge, of as observableOf, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { get, sortBy, union } from 'lodash';
import moment from 'moment';
import { EXTERNAL_APPOINTMENTS_TABLE_CONF } from './table-conf';
import { SchedulerService } from '../scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
	catchError,
	debounceTime,
	filter,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {
	CancelFormComponent,
	DeleteConfirmComponent,
	LocalStorageService,
	SharedService,
} from '../../shared';
import { rowsAnimation } from '../../animations';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateUtils } from '../../utils';
import { WsService } from '../../ws.service';
import { AppointmentDTO } from '../../model';
import {
	APPOINTMENT_STATUSES,
	AptStatus,
	getOrderStatusColor,
	getOrderStatusIcon,
	OrderFilter,
	SOURCES_COLORS,
} from '../shared/util';
import { AppointmentEditComponent } from '../appointment-edit/appointment-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { ExamSchedulerComponent } from '../exam-scheduler/exam-scheduler.component';

@Component({
	selector: 'ft-external-appointments',
	templateUrl: './external-appointments.component.html',
	styleUrls: ['./external-appointments.component.scss'],
	animations: [rowsAnimation],
})
export class ExternalAppointmentsComponent
	implements AfterViewInit, OnInit, OnDestroy
{
	cols = [];
	physicians: any[] = [];
	aets: any[] = [];
	displayedColumns = EXTERNAL_APPOINTMENTS_TABLE_CONF;
	columnsToDisplay: string[] = [];

	appointmentStatuses = APPOINTMENT_STATUSES;
	priorities: any;
	profile: any;

	canViewConfData: boolean;

	resultsLength = 0;
	isLoadingResults = true;

	isResultsLoaded = false;

	dataSource = new MatTableDataSource<AppointmentDTO>();

	radiologists: any[] = [];

	filterForm: FormGroup;
	filterChange = new BehaviorSubject('');
	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	private orderFilter = new OrderFilter();
	private orderFilterSubject = new BehaviorSubject<OrderFilter>(
		new OrderFilter()
	);
	todayFormat = 'HH:mm';
	private query = new BehaviorSubject<string>(null);
	defaultPageSize = 50;
	private subs: Subscription[] = [];

	public reasonForExams = [];

	sourceColors = SOURCES_COLORS;
	priorityMap: any = {};

	constructor(
		private service: SchedulerService,
		private shared: SharedService,
		private localStorage: LocalStorageService,
		private wsService: WsService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private snackBar: MatSnackBar,
		private router: Router,
		private _translate: TranslateService,
		private route: ActivatedRoute
	) {
		this.displayedColumns = EXTERNAL_APPOINTMENTS_TABLE_CONF;

		this.columnsToDisplay = sortBy(
			EXTERNAL_APPOINTMENTS_TABLE_CONF.filter(item => !item.hidden),
			'order'
		).map(it => it.label);
		this.columnsToDisplay = union(this.columnsToDisplay, ['action']);

		this.createFilterForm();

		const datasets = 'reasonForExams,priorities,performingPhysicians';
		this.subs.push(
			this.shared.getDatasets(datasets).subscribe(data => {
				const { performingPhysicians, priorities, reasonForExams } =
					data;
				this.reasonForExams = reasonForExams.filter(
					it => !['XRAY', 'UN', 'XSP'].includes(it.value)
				);
				this.radiologists = performingPhysicians;
				this.priorities = priorities;
				priorities.forEach(it => (this.priorityMap[it.id] = it.value));
			})
		);

		this.defaultPageSize =
			this.localStorage.getItem('order_page_size') || 50;

		const user = get(this.route.snapshot.data, 'user');
		this.profile = get(user, 'profile');
		this.canViewConfData = user.canViewConfidentialData;

		this.wsService.observeTopic('scheduler').subscribe(res => {
			if (res.topic === 'scheduler')
				this.orderFilterSubject.next(this.orderFilter);
		});
	}

	ngAfterViewInit() {
		this.buildQuery();
		this.resetPaginator();

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.query.pipe(debounceTime(250)),
		];

		merge(...observedFilters)
			.pipe(
				startWith(''),
				filter(it => it !== ''),
				switchMap(query => {
					this.isLoadingResults = true;
					this.localStorage.setItem(
						'order_page_size',
						this.paginator.pageSize
					);
					return this.service.getExternalAppointmentDTOs(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						query
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isResultsLoaded = false;
					this.resultsLength = data.totalElements;
					return data.content;
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isResultsLoaded = true;
					return observableOf([]);
				})
			)
			.subscribe((data: AppointmentDTO[]) => {
				this.dataSource.data = data;
			});
	}

	editAppointment(appointment: AppointmentDTO) {
		this.subs.push(
			this.dialog
				.open(AppointmentEditComponent, {
					data: { appointment },
					disableClose: true,
				})
				.afterClosed()
				.subscribe(nx => {
					if (nx)
						this.service
							.updateAppointmentStatus(
								appointment.id,
								AptStatus.planned
							)
							.subscribe(data => {
								this.orderFilterSubject.next(this.orderFilter);
								this.snackBar.open(
									this._translate.instant(
										'APPOINTMENT_UPDATED'
									),
									'',
									{ duration: 2000 }
								);
							});
				})
		);
	}

	trackById(index: number, item: any): string {
		return item.id;
	}

	private resetPaginator = () =>
		this.query.subscribe(() => (this.paginator.pageIndex = 0));

	onScheduleAppointment(appointment: AppointmentDTO) {
		this.subs.push(
			this.service
				.getPatientFullDTOById(appointment.patientId)
				.subscribe(patient => {
					this.subs.push(
						this.dialog
							.open(ExamSchedulerComponent, {
								data: {
									patient: patient,
									isr: appointment,
									selectedDateRange: {
										start: moment(),
										end: moment().add(15, 'h'),
									},
									editable: true,
									queryParam: null,
									panelClass: 'exam-dialog',
									is_external: true,
								},
								disableClose: true,
							})
							.afterClosed()
							.subscribe(res => {
								if (
									res &&
									!Object.prototype.hasOwnProperty.call(
										res,
										'isrId'
									)
								) {
									this.service
										.updateAppointmentDTOStatus(
											appointment.id,
											AptStatus.entered
										)
										.subscribe();
								}
							})
					);
				})
		);
	}

	onCancelAppointment(apt: AppointmentDTO) {
		this.subs.push(
			this.dialog
				.open(CancelFormComponent, { minWidth: '400px' })
				.afterClosed()
				.subscribe(reason => {
					if (reason) {
						this.service
							.cancelAppointmentDTO(apt.id, reason)
							.subscribe(() =>
								this.orderFilterSubject.next(this.orderFilter)
							);
					}
				})
		);
	}

	_get = (row, value: string): any => get(row, value);

	isEditable(apt): boolean {
		return ['unplanned', 'waiting_list'].includes(apt.appointmentStatus);
	}

	deleteApt(apt) {
		this.subs.push(
			this.dialog
				.open(DeleteConfirmComponent)
				.afterClosed()
				.subscribe(ok => {
					if (ok) {
						this.service
							.deleteAppointment(apt.id)
							.subscribe(() =>
								this.orderFilterSubject.next(this.orderFilter)
							);
					}
				})
		);
	}

	ngOnDestroy() {
		this.subs.forEach(it => it.unsubscribe());
	}

	ngOnInit() {
		this.filter.nativeElement.focus();
	}
	getOrderStatusIcon = (status: string): string => getOrderStatusIcon(status);
	getOrderStatusColor = (status: string): string =>
		getOrderStatusColor(status);

	changePeriod() {
		this.filterForm.get('period').patchValue('OT');
	}

	changeRange(e: MatButtonToggleChange) {
		const dateRange = DateUtils.PeriodDateRange(e.value);
		this.filterForm.patchValue(dateRange);
		this.orderFilter.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
		this.orderFilterSubject.next(this.orderFilter);
	}

	private createFilterForm() {
		this.filterForm = this.fb.group({
			key: '',
			startDate: new Date(),
			endDate: new Date(),
			period: 'TODAY',
			physicianId: null,
			appointmentStatus: null,
			priority: null,
			modality: null,
			reasonForExam: null,
		});

		this.filterForm.valueChanges.subscribe(value =>
			this.buildOrderFilter(value)
		);
	}

	private buildOrderFilter(value: any) {
		this.todayFormat =
			value.period === 'TODAY' ? 'HH:mm' : 'dd/MM/yyyy HH:mm';

		const startDate = moment(value.startDate).isValid()
			? moment(value.startDate)
			: moment().subtract(10, 'year');
		const endDate = moment(value.endDate).isValid()
			? moment(value.endDate)
			: moment().add(10, 'd');

		const start = startDate.format('YYYYMMDD');
		const end = endDate.format('YYYYMMDD');

		this.orderFilter.key = value.key;
		this.orderFilter.dateRange = `${start}-${end}`;
		const oStatus = value.appointmentStatus;
		this.orderFilter.appointmentStatus =
			oStatus && oStatus.length !== 0 ? oStatus.join('-') : 'ALL';
		this.orderFilter.priority =
			value.priority && value.priority.length !== 0
				? value.priority.join('-')
				: 'ALL';
		this.orderFilter.physicianId = value.physicianId;
		this.orderFilter.examType =
			value.reasonForExam && value.reasonForExam.length !== 0
				? value.reasonForExam.join('-')
				: this.reasonForExams.map(it => it.value).join('-');
		this.orderFilter.modality =
			value.modality && value.modality.length !== 0
				? value.modality.join('-')
				: 'ALL';

		this.orderFilterSubject.next(this.orderFilter);
	}

	private buildQuery() {
		this.orderFilterSubject
			.asObservable()
			.subscribe((filter: OrderFilter) =>
				this.query.next(
					[
						filter.key.replace('@', ''),
						filter.dateRange,
						filter.physicianId,
						filter.appointmentStatus,
						filter.priority,
						filter.examType,
						filter.modality,
					].join('@')
				)
			);
	}

	getPriorityColor(priority: string): string {
		return (
			this.priorities.find(it => it.value === priority)?.color ||
			'#a0a0a0'
		);
	}

	onRestoreAppointment(appointment: AppointmentDTO) {
		this.service
			.restoreAppointment(appointment.id)
			.subscribe(_ => this.orderFilterSubject.next(this.orderFilter));
	}
}
