<mat-card class="fx-fill-height fx-card">
	<mat-toolbar
		color="primary"
		class="fx-card-toolbar fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8 fx-height-42">
		<a mat-button routerLink="schedule-manager">
			<mat-icon
				class="toolbar-icon-gradient"
				fontIcon="mdi-calendar-text"
				fontSet="mdi"></mat-icon>
			&nbsp;<span class="fx-hide-lt-sm">{{
				'SCHEDULER' | translate
			}}</span>
		</a>

		<a
			(click)="agendaActive = true"
			[queryParams]="{ res: 'aet' }"
			mat-button
			routerLink="calendar">
			<mat-icon
				class="toolbar-icon-gradient-1"
				fontIcon="mdi-calendar-weekend"
				fontSet="mdi"></mat-icon>
			&nbsp;<span class="fx-hide-lt-sm">{{
				'CALENDAR' | translate
			}}</span>
		</a>

		@if (profile && profile.searchExam !== 'NONE') {
			<a
				(click)="agendaActive = false"
				mat-button
				routerLink="external-appointments">
				<mat-icon
					class="toolbar-icon-gradient-2"
					fontIcon="mdi-calendar-arrow-left"
					fontSet="mdi"></mat-icon>
				&nbsp;<span class="fx-hide-lt-sm">{{
					'EXTERNAL_APPOINTMENTS' | translate
				}}</span>
			</a>
		}

		<a
			(click)="agendaActive = false"
			mat-button
			routerLink="appointments-for-result">
			<mat-icon
				class="toolbar-icon-gradient"
				fontIcon="mdi-clipboard-file"
				fontSet="mdi"></mat-icon>
			&nbsp;<span class="fx-hide-lt-sm">{{
				'RDV_FOR_RESULT' | translate
			}}</span>
		</a>

		<span class="fx-grow-1"></span>

		@if (profile?.printTaskList !== 'NONE') {
			<button (click)="generateTasksList()" mat-button>
				<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
				<span class="fx-hide-lt-md">
					{{ 'GENERATE_TASK_LIST' | translate }}</span
				>
			</button>
		}

		@if (profile && profile.scheduleExam !== 'NONE') {
			<button
				(click)="$event.stopPropagation(); queryPacs()"
				class="plus-no-outline"
				mat-button>
				<mat-icon
					class="toolbar-icon-gradient"
					fontIcon="mdi-nas"
					fontSet="mdi"></mat-icon>
				&nbsp;<span class="fx-hide-lt-sm">{{
					'QUERY_PACS' | translate
				}}</span>
			</button>
		}

		<button (click)="addOrder()" class="plus-no-outline" mat-button>
			<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
			<span class="fx-hide-lt-sm">{{
				'ADD_APPOINTMENT' | translate
			}}</span>
		</button>

		@if (profile && profile.scheduleExam !== 'NONE') {
			<button
				(click)="$event.stopPropagation(); createExamWithPayment()"
				class="plus-no-outline toolbar-btn-gradient"
				mat-button>
				<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
				<span class="fx-hide-lt-sm">{{ 'NEW_EXAM' | translate }}</span>
			</button>
		}
	</mat-toolbar>
	<router-outlet></router-outlet>
</mat-card>
