import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SchedulerComponent } from './scheduler.component';
import { ExternalAppointmentsComponent } from './external-appointments';
import { UserResolver } from '../user.resolver';
import { ScheduleManagerComponent } from './schedule-manager/schedule-manager.component';
import { AppointmentsForResultComponent } from './appointments-for-result';
import { FtCalendarComponent } from '../shared';

const routes: Routes = [
	{
		path: '',
		component: SchedulerComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'schedule-manager' },
			{
				path: 'schedule-manager',
				component: ScheduleManagerComponent,
				resolve: { user: UserResolver },
			},
			{
				path: 'external-appointments',
				component: ExternalAppointmentsComponent,
				resolve: { user: UserResolver },
			},
			{
				path: 'appointments-for-result',
				component: AppointmentsForResultComponent,
				resolve: { user: UserResolver },
			},
			{
				path: 'calendar',
				component: FtCalendarComponent,
				resolve: { user: UserResolver },
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SchedulerRoutingModule {}
