export const SC_COLS = [
	{ header: 'PROCEDURE_CODE', attr: 'procedureCode', hidden: false },
	{ header: 'ACCESSION_NUMBER', attr: 'accessionNumber', hidden: false },
	{ header: 'SPSSTATUS', attr: 'spsStatus', hidden: false },
	{
		header: 'REPORT_COMPLETION_STATUS',
		attr: 'completedReportStatus',
		hidden: false,
	},
	{ header: 'MODALITY', attr: 'modality', hidden: false },
	{ header: 'IMAGINGCENTER', attr: 'imagingCenterName', hidden: false },
	{ header: 'PROCEDURE_TYPE', attr: 'examType', hidden: false },
	{
		header: 'PERFORMING_PHYSICIAN',
		attr: 'performingPhysician',
		hidden: false,
	},
	{ header: 'TECHNICIAN', attr: 'technician', hidden: false },
	{ header: 'PATIENTARRIVAL', attr: 'patientArrival', hidden: false },
	{ header: 'EXAM_START', attr: 'examinationStarted', hidden: false },
	{ header: 'EXAM_FINISH', attr: 'examinationFinished', hidden: false },
	{ header: 'TOTAL_AMOUNT', attr: 'totalAmount', hidden: false },
	{ header: 'DISCOUNT', attr: 'discount', hidden: false },
];
