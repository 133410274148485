import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { SchedulerService } from '../scheduler.service';
import { BehaviorSubject, merge, of as observableOf, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import {
	catchError,
	debounceTime,
	delay,
	first,
	map,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SC_COLS } from './table-conf';
import { rowsAnimation } from '../../animations';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	ColumnDataType,
	DisplayMode,
	FormatRule,
	FormattingRule,
	GeneralSettingDTO,
	PatientWorkflow,
	PaymentDTO,
	RendezVous,
	TableColumn,
	TableConfig,
	TargetDocument,
	ThermalPrintModel,
	WorkflowFilter,
	WorkflowItem,
	WorkItemSubtotal,
} from '../../model';
import { dropWhile, findIndex, get, map as _map, sortBy, union } from 'lodash';
import {
	checkCondition,
	CommentsComponent,
	DeleteConfirmComponent,
	getDisplayStyle,
	groupWorkflowData,
	hasPermission,
	LocalStorageService,
	PatientArrivedComponent,
	paymentColor,
	RdvPrintComponent,
	SharedService,
	shortName,
	SmsSenderComponent,
	specialAttributes,
	StockMovementComponent,
	waitingDuration,
} from '../../shared';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import numeral from 'numeral';
import { SettingService } from '../../setting/setting.service';
import {
	DateUtils,
	getCompetedReportIconColor,
	getIconColor,
	getPatientStatusIcon,
	getReportStatusIcon,
	reorderTableColumns,
	StringUtils,
} from '../../utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { ReportingService } from '../../reporting/reporting.service';
import { SelectionModel } from '@angular/cdk/collections';
import { WorkflowService } from '../../workflow/workflow.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WsService } from '../../ws.service';
import { EmailSendComponent } from '../../reporting/email-send/email-send.component';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../app-config.service';
import { PaymentFormComponent } from '../../shared/payment-form/payment-form.component';
import { PrintingHistoryComponent } from '../../shared/printing-history/printing-history.component';
import { ExamSchedulerComponent } from '../exam-scheduler/exam-scheduler.component';

const SC_TABLE_CONFIG_NAME = 'scheduler';

@Component({
	selector: 'ft-schedule-manager',
	templateUrl: './schedule-manager.component.html',
	styleUrls: ['./schedule-manager.component.scss'],
	animations: [
		rowsAnimation,
		trigger('detailExpand', [
			state(
				'collapsed',
				style({
					height: '0px',
					minHeight: '0',
					visibility: 'hidden',
					zIndex: '-1',
				})
			),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class ScheduleManagerComponent implements AfterViewInit, OnDestroy {
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	dataSource = new MatTableDataSource<PatientWorkflow>();

	isLoadingResults = true;
	isRateLimitReached = false;
	resultsLength: number;
	expandedElement: any;
	displayedColumns: TableColumn[] = [];
	columnsToDisplay: TableColumn[] = [];
	availableColumns = [];
	defaultPageSize = 100;
	examsColumnsToDisplay = SC_COLS;
	examsColumns = SC_COLS;
	generalSetting: GeneralSettingDTO;
	todayFormat = 'HH:mm';
	profile: any;
	canViewConfData: boolean;
	printers = [];
	selection = new SelectionModel<PatientWorkflow>(true, []);
	filterForm: FormGroup;

	private wf = new WorkflowFilter();
	private workflowFilterSubject = new BehaviorSubject<WorkflowFilter>(
		new WorkflowFilter()
	);
	private query = new BehaviorSubject<string>(null);
	currentDate = moment().format('LLLL');

	private nestedTableCols = 'sc_cols';

	private reasonForExams = [];
	schedulerTableConfig: TableConfig;
	linesFormattingRules: FormattingRule[] = [];
	columnsFormattingRules: FormattingRule[] = [];
	styles = {};
	totalAmount: WorkItemSubtotal = new WorkItemSubtotal();
	private subTotalSubject: BehaviorSubject<WorkItemSubtotal> =
		new BehaviorSubject(new WorkItemSubtotal());
	public readonly numberFormat: string;
	private user: any;
	private ws$: Subscription;
	modalities: any[] = ['CR', 'CT', 'DX', 'MG', 'MR', 'US', 'XA'];

	physicians: any;

	private _billingColumns: string[] = [
		'paymentStatus',
		'totalAmount',
		'paidAmount',
		'leftAmount',
		'discount',
		'globalDiscount',
		'billed',
	];
	private tableColumns: TableColumn[] = [];

	private static isConditionFilled(
		colDataType: ColumnDataType,
		formatRule: FormatRule,
		data: any,
		firstValue: any,
		secondValue: any
	): boolean {
		return checkCondition(
			colDataType,
			formatRule,
			data,
			firstValue,
			secondValue
		);
	}

	constructor(
		private service: WorkflowService,
		private localStorage: LocalStorageService,
		private scheduler: SchedulerService,
		private reportingService: ReportingService,
		private wsService: WsService,
		private dialog: MatDialog,
		private snack: MatSnackBar,
		private _config: AppConfigService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private translate: TranslateService,
		private shared: SharedService,
		private setting: SettingService
	) {
		this.generalSetting = this._config.generalSetting;
		this.numberFormat = this._config.numberFormat;
		this.user = get(this.route.snapshot.data, 'user');
		this.canViewConfData = this.user.canViewConfidentialData;
		this.profile = this.user.profile;

		this.shared
			.getRadiologists()
			.subscribe(data => (this.physicians = data));

		this.subTotalSubject
			.asObservable()
			.subscribe((data: WorkItemSubtotal) => {
				if (data) this.totalAmount = data;
			});

		this.defaultPageSize = parseInt(
			this.localStorage.getItem('sc_page_size') || 25,
			0
		);

		this.shared.getPrinters().subscribe(data => (this.printers = data));
		this.shared
			.getReasonForExams()
			.subscribe(data => (this.reasonForExams = data));

		const userId = this.user.id;
		this.setting
			.getTableConfig(SC_TABLE_CONFIG_NAME, userId)
			.subscribe(_tableConfig => {
				if (_tableConfig) {
					this.schedulerTableConfig = _tableConfig;

					this.tableColumns = get(
						_tableConfig,
						'tableColumns',
						[]
					).map((column: TableColumn) => {
						if (this._billingColumns.includes(column.label))
							column.available = this.profile?.managePayment;
						if (
							['sent', 'completedReportStatus'].includes(
								column.label
							)
						)
							column.available = false;
						if (column.label === 'synchronized')
							column.available = false;
						return column;
					});

					this.dispatchRules(_tableConfig.formattingRules);
					this._updateGridColumns(this.tableColumns);
				}
			});

		this.examsColumns =
			this.generalSetting?.billingRequired && this.profile?.managePayment
				? this.examsColumns
				: this.examsColumns.filter(
						col =>
							!['totalAmount', 'paidAmount', 'discount'].includes(
								col.attr
							)
					);
		this.examsColumnsToDisplay =
			this.generalSetting?.billingRequired && this.profile?.managePayment
				? this.examsColumnsToDisplay
				: this.examsColumnsToDisplay.filter(
						col =>
							!['totalAmount', 'paidAmount', 'discount'].includes(
								col.attr
							)
					);

		this.selection.changed.asObservable().subscribe(res =>
			console.log(
				'selected: ',
				res.added.map(it => it.accessionNumber)
			)
		);

		this.createFilterForm();

		setTimeout(() => (this.ws$ = this.subscribeToWsTopic()), 2000);
	}

	private _updateGridColumns(tableColumns: TableColumn[]): void {
		this._updateDisplayColumns(tableColumns);

		this.schedulerTableConfig.tableColumns = tableColumns;
		this.schedulerTableConfig.userId = this.user.id;

		this.setting
			.saveTableConfig(this.schedulerTableConfig)
			.subscribe(_tableConfig => {
				this.schedulerTableConfig = _tableConfig;
				this.tableColumns = get(_tableConfig, 'tableColumns', []);
				this._updateDisplayColumns(_tableConfig.tableColumns);
			});
	}

	private _updateDisplayColumns(tableColumns: TableColumn[]): void {
		this.availableColumns = sortBy(
			tableColumns.filter(it => it.available),
			'order'
		);

		this.displayedColumns = this.availableColumns.filter(
			(tc: TableColumn) => !tc.hidden
		);
		this.columnsToDisplay = union(
			[],
			_map(this.displayedColumns, 'value'),
			['action']
		);
	}

	ngOnDestroy() {
		this.ws$?.unsubscribe();

		this.localStorage.setItem(
			'last_filter_sc',
			this.filterForm.getRawValue()
		);
	}

	trackById = (index: number, item: any): string => item.accessionNumber;

	private createFilterForm() {
		this.filterForm = this.fb.group({
			key: '',
			startDate: new Date(),
			endDate: new Date(),
			period: 'TODAY',
			modality: null,
			technicianId: null,
			physicianId: null,
			reportStatus: null,
			patientStatus: null,
			paymentStatus: null,
			completedReportStatus: null,
		});

		this.filterForm.valueChanges.subscribe(value =>
			this.buildWorkflowFilter(value)
		);
	}

	changePeriod() {
		this.filterForm.get('period').patchValue('OT');
	}

	changeRange(e) {
		const dateRange = DateUtils.PeriodDateRange(e.value);
		this.filterForm.patchValue(dateRange);
		this.wf.dateRange = `${dateRange.startDate.format('YYYYMMDD')}-${dateRange.endDate.format('YYYYMMDD')}`;
		this.workflowFilterSubject.next(this.wf);
	}

	private buildQuery() {
		this.workflowFilterSubject
			.asObservable()
			.subscribe((wf: WorkflowFilter) => {
				this.query.next(
					[
						wf.key.replace('@', ''),
						wf.dateRange,
						wf.technicianId,
						wf.physicianId,
						wf.patientStatuses,
						wf.reportStatuses,
						wf.modalities,
						wf.paymentStatuses,
						'false',
					].join('@')
				);
			});
	}

	private buildWorkflowFilter(value: any) {
		this.todayFormat =
			value.period === 'TODAY' ? 'HH:mm' : this._config.dateTimeFormat;

		const startDate = moment(value.startDate).isValid()
			? moment(value.startDate)
			: moment().subtract(10, 'year');
		const endDate = moment(value.endDate).isValid()
			? moment(value.endDate)
			: moment().add(10, 'd');

		const start = startDate.format('YYYYMMDD');
		const end = endDate.format('YYYYMMDD');

		this.currentDate =
			value.period === 'OT'
				? ''
				: start === end
					? startDate.format('LLLL')
					: DateUtils.formatRange(
							startDate,
							endDate,
							this._config.appLang
						);

		this.wf.key = value.key;
		this.wf.dateRange = `${start}-${end}`;
		this.wf.modalities = value.modality ? value.modality.join('-') : 'ALL';
		let crs = value.completedReportStatus;
		if (crs && crs.includes('INPROGRESS'))
			crs = [
				...crs,
				'IN_PROGRESS',
				'TO_SIGN',
				'TO_TRANSCRIBE',
				'TO_VERIFY',
				'VERIFIED',
				'FINISHED',
			];
		if (crs && crs.includes('READY')) crs = [...crs, 'SIGNED'];
		this.wf.reportStatuses =
			crs && crs.length !== 0 ? crs.join('-') : 'ALL';
		this.wf.technicianId = value.technicianId;
		this.wf.physicianId =
			this.profile.manageExam === 'OWN'
				? this.user.id
				: value.physicianId;
		this.wf.patientStatuses =
			value.patientStatus && value.patientStatus.length !== 0
				? value.patientStatus.join('-')
				: 'ALL';
		this.wf.paymentStatuses =
			value.paymentStatus && value.paymentStatus.length !== 0
				? value.paymentStatus.join('-')
				: 'ALL';

		this.workflowFilterSubject.next(this.wf);
	}

	private resetPaginator = () =>
		this.query.subscribe(() => (this.paginator.pageIndex = 0));

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach(row => this.selection.select(row));
	}

	sendToBilling(row: PatientWorkflow) {
		this.service
			.syncWithBilling(row.accessionNumber)
			.subscribe(console.log);
	}

	deliverReport(row: WorkflowItem | PatientWorkflow) {
		this.reportingService
			.deliverCompletedReport(row.reportingTaskId)
			.subscribe(ok => {
				if (ok) this.workflowFilterSubject.next(this.wf);
			});
	}

	sendReport(row) {
		this.dialog
			.open(EmailSendComponent, {
				width: '600px',
				data: row,
				hasBackdrop: false,
				disableClose: true,
				position: { bottom: '0', right: '80px' },
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					const status = get(result, 'service');
					if (status !== 'ERROR') {
						this.workflowFilterSubject.next(this.wf);
						this.scheduler
							.updateReportingTaskStatus(
								row.reportingTaskId,
								'DELIVERED'
							)
							.subscribe();
					}
					this.snack.open(status, 'Ok', { duration: 2000 });
				}
			});
	}

	isAfterUpdate() {
		return moment().isSameOrAfter(moment('2019-10-23', 'YYYY-MM-DD'), 'd');
	}

	canPrintReport(status: string): boolean {
		return (
			['SENT', 'PRINTED', 'READY', 'DELIVERED'].includes(status) &&
			this.profile?.printReport !== 'NONE'
		);
	}

	drop(event: CdkDragDrop<string[]>) {
		this.tableColumns = reorderTableColumns(
			this.tableColumns,
			event.previousIndex,
			event.currentIndex
		);
		this._updateGridColumns(this.tableColumns);
	}

	drop2(event: CdkDragDrop<string[]>) {
		moveItemInArray(
			this.examsColumns,
			event.previousIndex,
			event.currentIndex
		);
		this.localStorage.setItem(this.nestedTableCols, this.examsColumns);

		this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
	}

	toggleColumn(col: TableColumn) {
		this.tableColumns = this.tableColumns.map((c: TableColumn) => {
			if (c.label === col.label) c.hidden = !col.hidden;
			return c;
		});

		this._updateGridColumns(this.tableColumns);
	}

	toggleColumn2(col: any) {
		const idx = findIndex(this.examsColumns, { header: col.header });
		this.examsColumns[idx].hidden = !col.hidden;
		this.examsColumnsToDisplay = this.examsColumns.filter(it => !it.hidden);
		this.localStorage.setItem(this.nestedTableCols, this.examsColumns);
	}

	isPatientAngry(row: WorkflowItem): boolean {
		return (
			row.patientStatus === 'WAITING' &&
			this.generalSetting &&
			waitingDuration(row) >
				(this.generalSetting.waitingDurationBeforeAlert || 30)
		);
	}

	formatNumeral(numValue: any, comma: boolean = false): any {
		return numeral(numValue).format(`${comma ? this.numberFormat : '0,0'}`);
	}

	getIconColor(row: WorkflowItem): string {
		return this.isPatientAngry(row)
			? '#f00'
			: getIconColor(row.patientStatus);
	}

	getPatientStatusIcon(row: WorkflowItem): string {
		if (this.isPatientAngry(row)) return 'mdi-emoticon-sad';
		return getPatientStatusIcon(row.patientStatus);
	}

	printPaymentReceipt(row: PatientWorkflow | WorkflowItem): void {
		const snackBarRef = this.snack.open('Printing receipt ...', '', {
			duration: 10000,
		});

		const payment = new PaymentDTO();
		payment.paymentID = row.paymentID;
		payment.patientName = row.patientName;

		if (this.generalSetting?.receiptPrintMode === 'CHROME') {
			this.scheduler
				.printPaymentReceipt(payment)
				.subscribe(_ => snackBarRef.dismiss());
		} else {
			this.scheduler.printCupsPaymentReceipt(payment).subscribe(ok => {
				if (ok['status'] !== 'ok') alert('Cannot print the receipt');
				snackBarRef.dismiss();
			});
		}
	}

	deleteExam(wf: WorkflowItem) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.scheduler
						.deleteExams(wf.accessionNumber)
						.subscribe(res => {
							if (res)
								this.snack.open(
									this.translate.instant('EXAM_DELETED'),
									'Ok',
									{ duration: 2000 }
								);
						});
				}
			});
	}

	addPayment(row: WorkflowItem) {
		this.dialog
			.open(PaymentFormComponent, {
				data: {
					paymentID: row.paymentID,
					patientID: row.patientID,
					accessionNumber: row.accessionNumber,
					patientName: row.patientName,
				},
				disableClose: true,
			})
			.afterClosed()
			.subscribe(order => {
				if (order)
					this.scheduler.orderPayment(order).subscribe(res => {
						if (res && res.id) {
							this.snack.open(
								this.translate.instant('NEW_PAYMENT_DONE'),
								'OK',
								{ duration: 2000 }
							);
						}
					});
			});
	}

	public printReportingTask(row, printer?: string) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);

		if (this.generalSetting.reportPrintMode === 'CHROME')
			this.reportingService
				.printSimpleReport(row.reportingTaskId, printer, 1)
				.subscribe(_ => matSnackBarRef.dismiss());
		else {
			this.reportingService
				.printCupsSimpleReport(row.reportingTaskId, printer, 1)
				.subscribe(res => {
					matSnackBarRef.dismiss();
					if (res['status'] !== 'ok')
						alert('Cannot print the report');
					else
						this.snack.open(
							this.translate.instant('FINALIZING_PRINTING'),
							'',
							{ duration: 3000 }
						);
				});
		}
	}

	public printBooklet(row, printer?: string) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);

		if (this.generalSetting.reportPrintMode === 'CHROME')
			this.reportingService
				.printReport(row.reportingTaskId)
				.subscribe(_ => matSnackBarRef.dismiss());
		else {
			this.reportingService
				.printCupsReport(row.reportingTaskId, printer, '1')
				.subscribe(response => {
					if (response['status'] !== 'ok')
						alert('Cannot print the booklet');
					else {
						matSnackBarRef.dismiss();
						this.snack.open(
							this.translate.instant('FINALIZING_PRINTING'),
							'',
							{ duration: 4000 }
						);
					}
				});
		}
	}

	getReportingStatusIcon = (status): string => getReportStatusIcon(status);
	getReportIconColor = (status): string => getCompetedReportIconColor(status);
	getPaymentStatusColor = (status: string): string => paymentColor(status);

	specialFormat(header: string): boolean {
		return specialAttributes(header);
	}

	enterPatient(row) {
		this.scheduler.getISRByAN(row.accessionNumber).subscribe(isr => {
			this.dialog
				.open(PatientArrivedComponent, {
					data: { isr: isr, canViewConfData: this.canViewConfData },
					width: '600px',
				})
				.afterClosed()
				.subscribe(isr => {
					if (isr) {
						this.scheduler
							.markPatientAsArrived(isr)
							.subscribe(next => {
								setTimeout(
									() =>
										this.workflowFilterSubject.next(
											this.wf
										),
									10000
								);
								this.snack.open(
									this.translate.instant(
										'PATIENT_AUTHORIZED'
									),
									'Ok',
									{ duration: 2000 }
								);
							});
					}
				});
		});
	}

	onPatientLeave(row) {
		this.scheduler.exitPatient(row.accessionNumber).subscribe(nxt => {
			setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
			this.snack.open(this.translate.instant('PATIENT_LEFT'), 'OK', {
				duration: 2000,
			});
		});
	}

	notPaid(row: any): boolean {
		return (
			['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus) ||
			!this.generalSetting?.billingRequired
		);
	}

	printRdvForResult(row) {
		const rdv = new RendezVous(
			row.patientID,
			row.accessionNumber,
			row.patientName,
			moment().format('YYYY-MM-DD'),
			row.procedureCode,
			''
		);

		this.dialog.open(RdvPrintComponent, { data: rdv });
	}

	showPatientFolder = row =>
		this.router.navigate(['/patients/folder', row.patientId]);
	can = (row: any, action: string): boolean =>
		(this.profile[action] !== 'NONE' && !row.confidential) ||
		this.canViewConfData;
	cannot = (action: string): boolean => this.profile[action] === 'NONE';
	isGranted = (row: WorkflowItem, status: string): boolean =>
		hasPermission(status, row);
	columnFormattingRules = (header: string): FormattingRule[] =>
		this.columnsFormattingRules
			? this.columnsFormattingRules.filter(
					it => it.targetColumn === header
				)
			: [];

	shortenName(name: string): string {
		return shortName(name);
	}

	expandRow(row: any) {
		this.expandedElement = this.expandedElement === row ? null : row;
	}

	getExamColor(row: PatientWorkflow | WorkflowItem): string {
		const exam = this.reasonForExams.find(it => it.value === row.examType);
		return exam ? exam.color : '';
	}

	printTicket(item: any) {
		const date = moment(item.patientArrival).format('DD/MM/YYYY HH:mm');
		const printable = new ThermalPrintModel(
			item.patientID,
			item.patientName,
			date,
			'-',
			item.accessionNumber,
			item.procedureCode
		);

		if (this.generalSetting.ticketPrintMode === 'CHROME')
			this.scheduler.printTicket(printable).subscribe();
		else {
			this.scheduler.printCupsTicket(printable).subscribe(ok => {
				if (ok['status'] !== 'ok') alert('Cannot print the ticket');
				else
					this.snack.open(
						this.translate.instant('PRINTING_IN_PROGRESS'),
						'',
						{ duration: 3000 }
					);
			});
		}
	}

	editExam(row: WorkflowItem | PatientWorkflow) {
		this.scheduler
			.getExamDetailsByAccessionNumber(row.accessionNumber)
			.subscribe(_examDetails => {
				if (_examDetails) {
					const editable =
						row.reportSignature === null &&
						['NOT_PAID', 'EXEMPT'].includes(row.paymentStatus);

					this.dialog.open(ExamSchedulerComponent, {
						data: {
							examDetails: _examDetails,
							editable: editable,
						},
					});
				}
			});
	}

	newExam(row: any) {
		this.scheduler
			.getPatientFullDTOById(row.patientId)
			.subscribe(patient => {
				if (patient)
					this.dialog
						.open(ExamSchedulerComponent, {
							data: {
								patient: patient,
								isr: null,
								selectedDateRange: {
									start: moment(),
									end: moment().add(15, 'm'),
								},
								editable: true,
								queryParam: null,
								admissionNumber: row.admissionNumber,
								accessionNumbers: row.workflowItems.map(
									it => it.accessionNumber
								),
								paymentID: row.paymentID,
								panelClass: 'exam-dialog',
							},
							disableClose: true,
						})
						.afterClosed()
						.subscribe(res => {
							if (res) this.workflowFilterSubject.next(this.wf);
						});
			});
	}

	debitStock(row: WorkflowItem | PatientWorkflow) {
		this.dialog
			.open(StockMovementComponent, { data: row, disableClose: true })
			.afterClosed()
			.subscribe(_ => this.workflowFilterSubject.next(this.wf));
	}

	ngAfterViewInit(): void {
		this.buildQuery();
		this.resetPaginator();

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.query.pipe(debounceTime(250)),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					const query = this.query.getValue();
					this.localStorage.setItem(
						'sc_page_size',
						this.paginator.pageSize
					);
					return this.service.getWorkflow(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						query
					);
				}),
				tap(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
				}),
				map(data => data['content'] as WorkflowItem[]),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => {
				this.dataSource.data = groupWorkflowData(
					data as WorkflowItem[]
				);

				this.service
					.calculateSubTotals(this.query.getValue())
					.subscribe(res => this.subTotalSubject.next(res));

				this.getLinesFormattingStyles();
			});

		const lastFilter = this.localStorage.getItem('last_filter_sc');
		if (lastFilter)
			setTimeout(() =>
				this.filterForm.patchValue(
					this.localStorage.getItem('last_filter_sc')
				)
			);
	}

	onSaveTableConfig(tableConfig: TableConfig) {
		this.setting.saveTableConfig(tableConfig).subscribe(res => {
			if (res) {
				this.schedulerTableConfig = res;
				this.dispatchRules(res.formattingRules);

				this.getLinesFormattingStyles();
			}
		});
	}

	getColumnStyle(
		colType: ColumnDataType,
		column: TableColumn,
		row: PatientWorkflow | WorkflowItem
	): any {
		if (!this.columnsFormattingRules) return;
		const rule = this.columnsFormattingRules.find(
			it => it.targetColumn === column.header
		);
		if (
			rule &&
			ScheduleManagerComponent.isConditionFilled(
				colType,
				rule.formatRule,
				row[column.label],
				rule.primaryFormatValue,
				rule.secondaryFormatValue
			)
		)
			return getDisplayStyle(rule.formattingStyle);
	}

	getColumnBooleanTextStyle(
		header: string,
		cellValue: any,
		displayMode = 'TEXT'
	): any {
		const rules = this.columnFormattingRules(header);

		const rule = rules.find(
			it => it.primaryFormatValue === cellValue.toString()
		);
		const style = rule ? rule.formattingStyle : null;

		const displayStyle = getDisplayStyle(style);

		if (rule && rule.formattingStyle.displayMode === displayMode)
			return displayStyle;
	}

	getColumnDisplayMode(header: string): DisplayMode {
		const rules = this.columnFormattingRules(header);
		const rule = rules[0];
		return rule ? rule.formattingStyle.displayMode : DisplayMode.TEXT;
	}

	getColumnFormattingIcon(header: string, cellValue: any): any {
		const rules = this.columnFormattingRules(header);
		const rule = rules.find(
			it => it.primaryFormatValue === cellValue?.toString()
		);
		return rule && rule.primaryFormatValue === cellValue.toString()
			? rule.formattingStyle.icon
			: '';
	}

	getColumnFormattingIconStyle(header: string, cellValue: any): any {
		const rules = this.columnFormattingRules(header);
		const rule = rules.find(
			it => it.primaryFormatValue === cellValue?.toString()
		);
		if (rule) return getDisplayStyle(rule.formattingStyle);
	}

	getColumnBooleanBadgeStyle(header: string, cellValue: any): any {
		return this.getColumnBooleanTextStyle(header, cellValue, 'BADGE');
	}

	getColumnStyleDisplayMode(
		colType: ColumnDataType,
		column: TableColumn,
		row: PatientWorkflow | WorkflowItem,
		displayMode: string = DisplayMode.TEXT
	): any {
		if (!this.columnsFormattingRules) return;

		const rule = this.columnsFormattingRules.find(
			it => it.targetColumn === column.header
		);
		if (
			rule &&
			rule.formattingStyle.displayMode === displayMode &&
			ScheduleManagerComponent.isConditionFilled(
				colType,
				rule.formatRule,
				row[column.label],
				rule.primaryFormatValue,
				rule.secondaryFormatValue
			)
		)
			return getDisplayStyle(rule.formattingStyle);
	}

	getRowFormattingStyle(row: WorkflowItem | PatientWorkflow): any {
		if (!this.linesFormattingRules) return;
		return this.styles[row.accessionNumber];
	}

	private getLinesFormattingStyles() {
		if (!this.linesFormattingRules) return;

		this.linesFormattingRules.forEach(rule => {
			const column = this.schedulerTableConfig.tableColumns.find(
				it => it.header === rule.targetColumn
			);
			this.dataSource.data.forEach(row => {
				if (
					ScheduleManagerComponent.isConditionFilled(
						column.type,
						rule.formatRule,
						row[column.label],
						rule.primaryFormatValue,
						rule.secondaryFormatValue
					)
				)
					this.styles[row.accessionNumber] = getDisplayStyle(
						rule.formattingStyle
					);
			});
		});
	}

	private dispatchRules(defaultRules: FormattingRule[]) {
		const rules = StringUtils.groupBy(defaultRules, 'appliedTo');
		this.linesFormattingRules = rules['ROW'];
		this.columnsFormattingRules = rules['COLUMN'];
	}

	calculateSum(label: string, comma = false): string {
		if (label === 'count') return String(this.resultsLength);
		return this.formatNumeral(get(this.totalAmount, label) || 0, comma);
	}

	calculateTotal(label: string): any {
		if (label === 'patientName')
			return (
				'Total patients: ' +
				this.formatNumeral(this.dataSource.data.length)
			);
	}

	addComment(row: WorkflowItem): void {
		this.dialog
			.open(CommentsComponent, {
				data: row.noteAlert,
				width: '400px',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(comments => {
				if (comments && comments === 'dismiss') return;
				this.reportingService
					.saveNoteAlert(row.reportingTaskId, comments)
					.subscribe(res =>
						this.snack.open(
							this.translate.instant('COMMENT_SAVED'),
							'OK',
							{ duration: 2000 }
						)
					);
			});
	}

	sendSMS(row) {
		this.dialog
			.open(SmsSenderComponent, { data: row, minWidth: '360px' })
			.afterClosed()
			.subscribe(ok => {
				if (ok)
					this.snack.open('SMS envoyé à ' + row.patientName, '', {
						duration: 3000,
					});
			});
	}

	private subscribeToWsTopic(): Subscription {
		return this.wsService
			.observeTopic('workflow')
			.pipe(delay(1000))
			.subscribe({
				next: res => {
					if (res.topic === 'workflow') {
						console.log('update workflow');
						this.workflowFilterSubject.next(this.wf);
					}
				},
				error: err => console.log(err),
				complete: () => {
					console.log('complete');
				},
			});
	}

	printAttestation(row: any) {
		const matSnackBarRef = this.snack.open(
			this.translate.instant('PRINTING_IN_PROGRESS'),
			'',
			{ duration: 10000 }
		);
		this.shared
			.printAttestation(row.id)
			.subscribe(_ => matSnackBarRef.dismiss());
	}

	generateEfactUrl(row) {
		const ans = [
			row.accessionNumber,
			...row.workflowItems.map(it => it.accessionNumber),
		].join('@');
		this.scheduler
			.generateEfactUrl(ans)
			.pipe(first())
			.subscribe(efact => open(efact.url));
	}

	generateEHealthBoxUrl(row) {
		this.scheduler
			.generateEHealthBoxUrl(row.accessionNumber)
			.pipe(first())
			.subscribe(eHBox => open(eHBox.url));
	}

	eFactActivated = () => this._config.eFactActivated;
	eHealthBoxActivated = () => this._config.eHealthBoxActivated;

	displayPrintingHistory(row) {
		this.dialog.open(PrintingHistoryComponent, {
			data: {
				targetDocuments: [
					TargetDocument.THERMAL_TICKET,
					TargetDocument.PAYMENT_RECEIPT,
					TargetDocument.ATTESTATION,
					TargetDocument.RDV_FOR_RESULT,
				],
				documentId: row.accessionNumber,
			},
		});
	}

	sendHL7Report(row) {
		this.reportingService.sendHL7Report(row).subscribe(value => {
			if (value)
				this.snack.open('Compte rendu diffusé', '', { duration: 2000 });
		});
	}

	downloadBooklet(row) {
		const filename = [
			row.patientName.trim().split(' ').join('_'),
			row.patientArrival.replaceAll('.', ':'),
		].join('_');
		const matSnackBarRef = this.snack.open(
			this.translate.instant('DOWNLOADING'),
			'',
			{ duration: 12000 }
		);
		this.reportingService
			.downloadReport(row.reportingTaskId, filename)
			.subscribe(_ => matSnackBarRef.dismiss());
	}
}
