import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SchedulerService } from './scheduler.service';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Router } from '@angular/router';
import { Profile, QueryParam, GeneralSettingDTO } from '../model';
import { TranslateService } from '@ngx-translate/core';
import { PacsSearchComponent, SharedService } from '../shared';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';
import { TasksListComponent } from '../shared/tasks-list/tasks-list.component';
import { ExamSchedulerComponent } from './exam-scheduler/exam-scheduler.component';

@Component({
	selector: 'ft-scheduler',
	templateUrl: './scheduler.component.html',
	styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, OnDestroy {
	examStatuses: string[] = [];

	generalSetting: GeneralSettingDTO;

	profile: Profile;
	aets: any[];
	agendaActive: boolean = true;
	defaultView: string = '/schedule-manager';

	resourceFilterVisible: boolean = false;
	reasonForExams: any[];

	private schedulerView: string;

	subs: Subscription[] = [];

	constructor(
		private service: SchedulerService,
		private sharedService: SharedService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private router: Router
	) {
		const user = JSON.parse(localStorage.getItem('user'));
		this.profile = user.profile;

		this.subs.push(
			this.translate
				.get('EXAM_STATUSES')
				.subscribe(res => (this.examStatuses = res.split('_')))
		);
		this.subs.push(
			this.service.broadcast.subscribe(
				viewType =>
					(this.resourceFilterVisible =
						viewType === 'resourceTimeGridDay')
			)
		);
	}

	createExamWithPayment(queryParam: QueryParam = null) {
		if (queryParam)
			queryParam.patientID = queryParam.patientID
				?.replaceAll('/', '_')
				?.replaceAll(' ', '');
		this.dialog
			.open(ExamSchedulerComponent, {
				data: {
					selectedDateRange: {
						start: moment(),
						end: moment().add(10, 'm'),
					},
					editable: true,
					queryParam: queryParam,
					panelClass: 'exam-dialog',
				},
				disableClose: true,
				position: { top: '16px' },
				minWidth: '50vw',
			})
			.afterClosed()
			.subscribe(res => {
				if (res && res['isrId']) {
					//TODO(Should mark exam as finished)
				}
			});
	}

	addOrder() {
		this.subs.push(
			this.dialog
				.open(AppointmentEditComponent, {
					data: { patient: null, isr: null },
					disableClose: true,
				})
				.afterClosed()
				.subscribe(/*res => this.createOrder(res)*/)
		);
	}

	ngOnInit() {
		const datasets = 'aets,reasonForExams,generalSetting';
		this.subs.push(
			this.sharedService.getDatasets(datasets).subscribe(data => {
				datasets.split(',').forEach(it => (this[it] = data[it]));

				localStorage.setItem(
					'eid_api_url',
					this.generalSetting.scannerIdUrl
				);

				this.getDefaultView(this.generalSetting);
				if (
					['EXTERNAL_APPOINTMENTS', 'CALENDAR'].includes(
						this.schedulerView
					)
				)
					this.router.navigateByUrl(this.defaultView).then(_ => null);
			})
		);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	private getDefaultView(setting: GeneralSettingDTO) {
		this.schedulerView = setting.schedulerDefaultView;
		switch (setting.schedulerDefaultView) {
			case 'EXTERNAL_APPOINTMENTS':
				this.defaultView = '/scheduler/external-appointments';
				this.agendaActive = false;
				break;
			case 'CALENDAR':
				this.defaultView = '/scheduler/calendar';
				break;
			case 'SCHEDULER':
				this.defaultView = '/scheduler/schedule-manager';
				this.agendaActive = false;
				break;
		}
	}

	queryPacs() {
		this.dialog
			.open(PacsSearchComponent, {
				data: '',
				minHeight: '100vh',
				maxHeight: '100vh',
				minWidth: '90vw',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(res => {
				if (res && res.key === 'new')
					this.createExamWithPayment(res.data);
			});
	}

	generateTasksList() {
		this.dialog.open(TasksListComponent);
	}
}
